import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')
];

export const server_loads = [0,2,3,4,7,9];

export const dictionary = {
		"/": [14],
		"/(auth)/accounts/launch": [~35,[6]],
		"/(auth)/accounts/login": [~36,[6]],
		"/(auth)/accounts/password/reset_password/[token]": [~38,[6]],
		"/(auth)/accounts/password/reset": [37,[6]],
		"/(auth)/accounts/signout/[slug]": [~39,[6]],
		"/(auth)/accounts/signup": [~40,[6]],
		"/(auth)/accounts/signup/activate": [~41,[6]],
		"/(auth)/accounts/success": [42,[6]],
		"/(auth)/accounts/support_access/[access_token]": [~43,[6]],
		"/(admin)/admin/bookings/bookingSets": [~16,[2,3]],
		"/(admin)/admin/bookings/bookingSets/[id]": [~17,[2,3]],
		"/(admin)/admin/bookings/booking/[id]": [~15,[2,3]],
		"/(admin)/admin/bookings/cancelled": [~18,[2,3]],
		"/(admin)/admin/bookings/completed": [~19,[2,3]],
		"/(admin)/admin/bookings/upcoming": [20,[2,3]],
		"/(admin)/admin/client_side": [21,[2]],
		"/(admin)/admin/home": [~22,[2]],
		"/(admin)/admin/invite_earn": [23,[2]],
		"/(admin)/admin/locations": [24,[2]],
		"/(admin)/admin/locations/[location_id]": [~25,[2]],
		"/(admin)/admin/payments": [26,[2,4]],
		"/(admin)/admin/payments/add_card": [~27,[2,4]],
		"/(admin)/admin/payments/buy": [28,[2,4]],
		"/(admin)/admin/payments/card_added": [~29,[2,4]],
		"/(admin)/admin/profile": [~30,[2]],
		"/(admin)/admin/sweepcred": [~31,[2]],
		"/(admin)/admin/sweepstars": [~32,[2]],
		"/(admin)/admin/sweepstars/sweepstar/[username]": [~33,[2]],
		"/(admin)/admin/vouchers": [~34,[2]],
		"/(info)/app": [~61,[11]],
		"/(funnel)/(booking)/booking/accounts/login": [~45,[7,8]],
		"/(funnel)/(booking)/booking/accounts/password/reset_password/[token]": [~47,[7,8]],
		"/(funnel)/(booking)/booking/accounts/password/reset": [46,[7,8]],
		"/(funnel)/(booking)/booking/accounts/signup": [~48,[7,8]],
		"/(funnel)/(booking)/booking/lego-demo": [49,[7,8]],
		"/(funnel)/(booking)/booking/payments": [50,[7,8,9]],
		"/(funnel)/(booking)/booking/payments/add_card": [~51,[7,8,9]],
		"/(funnel)/(booking)/booking/payments/buy": [52,[7,8,9]],
		"/(funnel)/(booking)/booking/payments/card_added": [~53,[7,8,9]],
		"/(funnel)/(booking)/booking/step-complete": [54,[7,8]],
		"/(funnel)/(booking)/booking/step-one": [55,[7,8]],
		"/(funnel)/(booking)/booking/step-start": [56,[7,8]],
		"/(funnel)/(booking)/booking/step-three": [57,[7,8]],
		"/(funnel)/(booking)/booking/step-two": [58,[7,8]],
		"/(funnel)/(booking)/booking/worker": [~59,[7,8]],
		"/(auth)/book/feedback/[key]": [~44,[6]],
		"/(funnel)/(location)/location": [60,[7,10]],
		"/products/[slug]": [~62,[12]],
		"/sentry-example": [63],
		"/sweepstar/[username]": [~64,[13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';